import React, { Suspense, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { hasToken } from '../api/axios';

export default function Auth() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasToken()) {
      navigate('/login');
    }
  }, [navigate]);

  return (
    <div className="auth-wrapper">
      {/* Header can be here */}
      <Suspense fallback={null}>
        {/* Outlet is display as child route */}
        <Outlet />
      </Suspense>
    </div>
  );
}
