import { useEffect, useRef, useState } from "react";
import {
  createCustomer,
  deleteCustomer,
  getListCustomer,
  updateCustomer,
} from "../../api/customer";
import { useQuery } from "react-query";
import { isNumeric } from "../../utils";

const useCustomerMaster = () => {
  const [arrCustomers, setArrCustomers] = useState([]);
  const [activeSortColumn, setActiveSortColumn] = useState(0);
  const [sortColumnDirection, setSortColumnDirection] = useState("desc");
  const [sortedData, setSortedData] = useState([]);
  const [newCustomer, setNewCustomer] = useState({
    name: "",
    tel: "",
    remarks: "",
  });

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const [editCustomer, setEditCustomer] = useState(null);
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const [isPopupOpenUpdate, setIsPopupOpenUpdate] = useState(false);
  const [isPopupOpenDelete, setIsPopupOpenDelete] = useState(false);
  const [focusState, setFocusState] = useState({
    name: false,
    tel: false,
  });

  const nameInputRef = useRef(null);

  const [errors, setErrors] = useState({
    name: "",
    tel: "",
    remarks: "",
  });

  const [errorsUpdate, setErrorsUpdate] = useState({
    name: "",
    tel: "",
    remarks: "",
  });

  const { data, refetch } = useQuery(
    ["customers", page, rowsPerPage],
    () => {
      const params = {
        page: page + 1,
        per_page: rowsPerPage,
      };
      return getListCustomer(params);
    },
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (data) {
      setArrCustomers(transformCustomerData(data.data.data));
      setTotal(data.data.total);
    }
  }, [data]);

  useEffect(() => {
    setSortedData(arrCustomers.sort((a, b) => b.id - a.id));
  }, [arrCustomers]);

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  const transformCustomerData = (data) => {
    return data.map((customer) => ({
      id: customer.id.toString(),
      date: new Date(customer.created_at).toISOString().split("T")[0],
      time: new Date(customer.created_at).toLocaleTimeString("ja-JP", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      }),
      name: customer.name,
      tel: customer.tel,
      remarks: customer.remarks || "",
    }));
  };

  const handleSort = (columnIndex) => {
    const isSorted = activeSortColumn === columnIndex;
    const direction = isSorted
      ? sortColumnDirection === "asc"
        ? "desc"
        : "asc"
      : "desc";
    const sorted = [...sortedData].sort((a, b) => {
      let aValue = a[Object.keys(a)[columnIndex]];
      let bValue = b[Object.keys(b)[columnIndex]];

      // if aValue and bValue are numberic, sort parseInt
      if (isNumeric(aValue) && isNumeric(bValue)) {
        aValue = parseInt(aValue);
        bValue = parseInt(bValue);
      }

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setSortedData(sorted);
    setActiveSortColumn(columnIndex);
    setSortColumnDirection(direction);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCustomer({ ...newCustomer, [name]: value });
    setErrors({ name: "", tel: "", remarks:"" });
  };

  const handleAddCustomer = async () => {
    try {
      const newCustomerData = {
        name: newCustomer.name,
        tel: newCustomer.tel,
        remarks: newCustomer.remarks,
      };

      const response = await createCustomer(newCustomerData);

      if (response && response.data) {
        refetch();
        setNewCustomer({ name: "", tel: "", remarks: "" });
        setErrors({ name: "", tel: "", remarks:"" });
      } else {
        alert("エラーが発生しました。");
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const fieldErrors = error.response.data.message;
        setErrors((prev) => ({
          ...prev,
          ...fieldErrors,
        }));
      }
      else {
        alert("エラーが発生しました。");
      }
    }
  };

  const handleUpdateCustomer = async (updatedCustomer) => {
    try {
      const { id, name, tel, remarks } = updatedCustomer;
      const updatedData = {
        name,
        tel: tel,
        remarks: remarks,
      };

      const response = await updateCustomer(id, updatedData);

      if (response && response.data) {
        refetch();
        return true;
      } else {
        alert("エラーが発生しました。");
        return false;
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const fieldErrors = error.response.data.message;
        setErrorsUpdate((prev) => ({
          ...prev,
          ...fieldErrors,
        }));
      }
      else {
        alert("エラーが発生しました。");
      }
      return false;
    }
  };

  const handleDeleteCustomer = async (id) => {
    try {
      const response = await deleteCustomer(id);

      if (response.success) {
        refetch();
      } else {
        alert("エラーが発生しました。");
      }
    } catch (error) {
      alert("エラーが発生しました。");
    }
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    const maxPage = Math.floor(total / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(Math.min(page, maxPage));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleFocus = (field) => {
    setFocusState((prevState) => ({
      ...prevState,
      [field]: true,
    }));
  };

  const handleBlur = (field) => {
    setFocusState((prevState) => ({
      ...prevState,
      [field]: false,
    }));
  };

  const handleEditClick = (customer) => {
    setEditCustomer(customer);
    setIsPopupOpenUpdate(true);
  };

  const handleDeleteClick = (customer) => {
    setCustomerToDelete(customer);
    setIsPopupOpenDelete(true);
  }

  const handleClosePopup = () => {
    setIsPopupOpenUpdate(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditCustomer((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrorsUpdate({ name: "", tel: "", remarks: "" });
  };

  return {
    arrCustomers,
    activeSortColumn,
    sortColumnDirection,
    sortedData,
    newCustomer,
    rowsPerPage,
    page,
    total,
    editCustomer,
    customerToDelete,
    isPopupOpenUpdate,
    isPopupOpenDelete,
    focusState,
    nameInputRef,
    errors,
    errorsUpdate,
    setIsPopupOpenDelete,
    handleRowsPerPageChange,
    handlePageChange,
    handleSort,
    handleInputChange,
    handleAddCustomer,
    handleUpdateCustomer,
    handleDeleteCustomer,
    handleFocus,
    handleBlur,
    handleEditClick,
    handleDeleteClick,
    handleClosePopup,
    handleChange
  };
};

export default useCustomerMaster;
