import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <Container component="main" maxWidth="md" style={{ textAlign: 'center', marginTop: '50px' }}>
      <Box>
        <Typography variant="h2" component="h1" gutterBottom>
          404
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          お探しのページは見つかりませんでした。
        </Typography>
        <Typography variant="body1" gutterBottom>
          ページが移動または削除された可能性があります。
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/">
          ホームに戻る
        </Button>
      </Box>
    </Container>
  );
};

export default NotFound;
