import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import DatePickerRangeComponent from "../../components/DatePickerRange";
import styled from "./style.module.css";
import { useQuery } from "react-query";
import { getRevenueByIntroducer } from "../../api/dailyReport";
import { STALE_TIME_DEFAULT } from "../../constants";
import { formatNumberWithUnit } from "../../utils";

const styleTableHead = {
  backgroundColor: "#000",
  color: "#fff",
  display: "block",
  textAlign: "center",
  verticalAlign: "middle",
  padding: "auto",
  paddingLeft: 0,
  paddingRight: 0,
};

const styleTableBody = {
  ...styleTableHead,
  color: "#000",
  backgroundColor: "transparent",
};

const RevenueIntroducer = () => {
  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs(new Date()));

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const { data: dataIntroducer } = useQuery(
    ["list_revenue_introducer", startDate, endDate],
    () => {
      return getRevenueByIntroducer({
        start_date: startDate.format("YYYY-MM-DD"),
        end_date: endDate.format("YYYY-MM-DD"),
      });
    },
    {
      keepPreviousData: true,
      staleTime: STALE_TIME_DEFAULT,
    }
  );

  return (
    <Container maxWidth="xl">
      <div className={`${styled.dateRangeWrapper}`}>
        <Typography
          sx={{
            fontSize: {
              md: 20,
              xs: 10,
            },
            letterSpacing: {
              md: "3px",
              xs: 0,
            },
            color: "#fff",
            textDecoration: "none",
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;紹介者報酬
        </Typography>
        <div className={`${styled.dateRangeContainer}`}>
          <DatePickerRangeComponent
            onDateChange={(start, end) => handleDateChange(start, end)}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </div>

      <Container
        sx={{
          padding: "0px !important",
        }}
      >
        <Box sx={{ marginTop: "20px" }}>
          <Paper
            sx={{
              backgroundColor: "rgb(255, 255, 255)",
              color: "rgba(0, 0, 0, 0.87)",
              transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              borderRadius: "4px",
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
              width: "100%",
              marginBottom: "16px",
              overflow: "auto",
            }}
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                  >
                    <TableCell sx={styleTableHead}>紹介者名</TableCell>
                    <TableCell sx={styleTableHead}>紹介者報酬</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataIntroducer?.data?.map((item, index) => (
                    <TableRow
                      key={item.introducer_id}
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        backgroundColor:
                          index % 2 === 0 ? "rgba(0, 0, 0, 0.04)" : "#fff",
                      }}
                    >
                      <TableCell sx={styleTableBody}>
                        {item.introducer_name}
                      </TableCell>
                      <TableCell sx={styleTableBody}>
                        {formatNumberWithUnit(item.introducer_amount, "円")}
                      </TableCell>
                    </TableRow>
                  ))}
                  {(!dataIntroducer?.data || dataIntroducer?.data?.length === 0) && (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        <Typography>該当データがありません。</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Container>
    </Container>
  );
};

export default RevenueIntroducer;
