import { useEffect, useState } from "react";
import { getListRevenue } from "../../api/revenue";
import { useQuery } from "react-query";
import { formatCurrency, formatJapaneseDate, formatNumberWithUnit } from "../../utils";

const useRevenueByDay = () => {
  const [arrRevenues, setArrRevenues] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [currentMonth, setCurrentMonth] = useState("");
  const typeSearch = "by-date"

  const fetchRevenueData = async (month) => {
    const year = new Date().getFullYear();
    const start_date = `${year}-${String(month).padStart(2, "0")}-01`;
    const end_date = `${year}-${String(month).padStart(2, "0")}-${new Date(
      year,
      month,
      0
    ).getDate()}`;
    const params = {
      start_date,
      end_date,
    };
    const data = await getListRevenue(typeSearch, params);
    return data;
  };

  const { data } = useQuery(
    ["revenue", currentMonth],
    () => fetchRevenueData(currentMonth),
    {
      keepPreviousData: true,
      enabled: Boolean(currentMonth)
    }
  );

  useEffect(() => {
    if (data) {
      setArrRevenues(transformRevenueData(data.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const date = new Date();
    const month = date.getMonth() + 1;
    setCurrentMonth(month);
  }, []);

  const transformRevenueData = (data) => {
    return data.map((revenue) => {
      const total_price = parseInt(revenue.total_price, 10);
      const total_customer = parseInt(revenue.total_customer, 10) || 1;
      const avg_price_per_customer = total_price / total_customer;
      return {
        date: formatJapaneseDate(revenue.date),
        total_price: formatCurrency(revenue.total_price),
        total_customer: formatNumberWithUnit(revenue.total_customer, "人"),
        total_nomination: formatNumberWithUnit(revenue.total_nomination, "件"),
        avg_price_per_customer: formatCurrency(avg_price_per_customer),
        nomination_fee: formatCurrency(revenue.nomination_fee),
      }
    });
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChangeMonth = (e) => {
    const value = e.target.value;
    if (value === "") {
      setCurrentMonth("");
      return;
    }
    const newMonth = parseInt(value, 10);
    if (!isNaN(newMonth) && newMonth >= 1 && newMonth <= 12) {
      setCurrentMonth(newMonth);
    }
  };

  return {
    arrRevenues,
    isFocused,
    currentMonth,
    handleFocus,
    handleBlur,
    handleChangeMonth,
  };
};

export default useRevenueByDay;
