import "./App.css";
import Root from "./routes/rootRoute";

function App() {
  return (
    <Root />
  );
}

export default App;
