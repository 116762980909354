import { Typography } from "@mui/material";

function TitleComponent({ valueText }) {
  return (
    <Typography
      component="p"
      sx={{
        fontSize: {
          xs: 20,
          md: 28,
        },
        color: "#fff",
        top: "104px",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "24px",
        letterSpacing: "3px",
        margin: "16px 0",
        textDecoration: "none",
        width: "100%",
        paddingBottom: "15px",
        borderBottom: "2px solid white",
        textAlign: "left",
      }}
    >
      {valueText}
    </Typography>
  );
}

export default TitleComponent;
