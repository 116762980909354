import { useCallback, useEffect, useState } from "react";
import {
  getListTherapistManagement,
  updateTherapistManagement,
} from "../../api/therapistsManagement";
import { useQuery } from "react-query";
import { isNumeric } from "../../utils";

const useTherapistManagementMaster = () => {
  const [arrTherapistManagements, setArrTherapistManagements] = useState([]);
  const [activeSortColumn, setActiveSortColumn] = useState(0);
  const [sortColumnDirection, setSortColumnDirection] = useState("desc");
  const [sortedData, setSortedData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const [errorsUpdate, setErrorsUpdate] = useState({
    name: "",
    tel: "",
    remarks: "",
  });

  const { data, refetch } = useQuery(
    ["therapistManagements", page, rowsPerPage],
    () => {
      const params = {
        page: page + 1,
        per_page: rowsPerPage,
      };
      return getListTherapistManagement(params);
    },
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (data) {
      setArrTherapistManagements(
        transformTherapistManagementData(data.data.data)
      );
      setTotal(data.data.total);
    }
  }, [data]);

  useEffect(() => {
    setSortedData(arrTherapistManagements.sort((a, b) => b.id - a.id));
  }, [arrTherapistManagements]);

  const transformTherapistManagementData = (data) => {
    return data.map((therapistManagement) => ({
      id: therapistManagement.id.toString(),
      name: therapistManagement.name,
      order_number: therapistManagement.order_number,
      status: therapistManagement.status === 2 ? "表示" : "非掲載",
      date: "",
      nomination_fee: parseInt(therapistManagement.nomination_fee),
      main_nomination_fee: therapistManagement.main_nomination_fee
        ? parseInt(therapistManagement.main_nomination_fee)
        : `1000+parseInt(mainNominationCount/5)*500`,
      totalNominationCount: "",
      remarks: therapistManagement.remarks || "",
    }));
  };

  const handleSort = useCallback(
    (columnIndex) => {
      const isSorted = activeSortColumn === columnIndex;
      let direction = isSorted
        ? sortColumnDirection === "asc"
          ? "desc"
          : "asc"
        : "desc";
      sortedData.sort((a, b) => {
        let aValue = a[Object.keys(a)[columnIndex]];
        let bValue = b[Object.keys(b)[columnIndex]];

        // if aValue and bValue are numberic, sort parseInt
        if (isNumeric(aValue) && isNumeric(bValue)) {
          aValue = parseInt(aValue);
          bValue = parseInt(bValue);
        }

        if (aValue < bValue) return direction === "asc" ? -1 : 1;
        if (aValue > bValue) return direction === "asc" ? 1 : -1;
        return 0;
      });
      setSortedData([...sortedData]);
      setActiveSortColumn(columnIndex);
      setSortColumnDirection(direction);
    },
    [activeSortColumn, sortColumnDirection, sortedData]
  );

  const transformTherapistManagementsForUpdate = (arrTherapistManagements) => {
    return arrTherapistManagements.map((item) => ({
      id: item.id,
      order_number: item.order_number,
      status: item.status === "表示" ? 2 : 1,
    }));
  };

  const handleUpdateTherapistManagement = async () => {
    try {
      const transformedData = transformTherapistManagementsForUpdate(
        arrTherapistManagements
      );
      const updatedData = {
        therapists: transformedData,
      };

      const response = await updateTherapistManagement(updatedData);

      if (response && response.data) {
        refetch();
        return true;
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const fieldErrors = error.response.data.message;
        setErrorsUpdate((prev) => ({
          ...prev,
          ...fieldErrors,
        }));
      } else {
        alert("エラーが発生しました。");
      }
      return false;
    }
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    const maxPage = Math.floor(total / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(Math.min(page, maxPage));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handlePublishChange = (event, rowIndex) => {
    const newDataCustome = [...arrTherapistManagements];

    newDataCustome[rowIndex].status = event.target.value;

    setArrTherapistManagements(newDataCustome);
  };

  return {
    arrTherapistManagements,
    activeSortColumn,
    sortColumnDirection,
    sortedData,
    rowsPerPage,
    page,
    total,
    errorsUpdate,
    handleRowsPerPageChange,
    handlePageChange,
    handleSort,
    handleUpdateTherapistManagement,
    handlePublishChange,
  };
};

export default useTherapistManagementMaster;
