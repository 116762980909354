import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import { useState, useEffect } from "react";
import { TextField } from "@mui/material";

function DatePickerComponent({
  currentDate,
  onDateChange = () => {},
  margintop,
  views = ["year", "month", "day"],
  format = "YYYY年MM月DD日",
}) {
  const [value, setValue] = useState(dayjs(currentDate));
  const renderInput = (params) => (
    <TextField {...params} type="tel" placeholder="yyyy年mm月dd日" />
  );
  const handleChange = (newValue) => {
    setValue(newValue);
    onDateChange(newValue);
  };
  useEffect(() => {
    setValue(dayjs(currentDate));
  }, [currentDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
      <DatePicker
        value={value}
        onChange={handleChange}
        className="hide-fieldset"
        sx={{
          "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd":
            { paddingRight: 0 },
          "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd::before":
            {
              content: "''",
              borderBottom: "1px solid rgba(0, 0, 0,0.42)",
              position: "absolute",
              left: 0,
              bottom: 0,
              right: 0,
              transition:
                "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              pointerEvents: "none",
            },
          "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd:hover::before":
            {
              borderBottom: "2px solid rgba(0, 0, 0)",
            },
          "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd::after":
            {
              content: "''",
              position: "absolute",
              borderBottom: "2px solid rgb(25, 118, 210);",
              left: 0,
              bottom: 0,
              right: 0,
              transform: "scale(0)",
              transition: "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
              pointerEvents: "none",
            },
          "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd:focus-within::before":
            {
              borderBottomColor: "blue",
            },
          "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd:focus-within::after":
            {
              borderBottomColor: "blue",
            },
          "& input": { padding: "4px 0 5px" },
          marginTop: margintop ? "16px" : "0",
        }}
        renderLoading={renderInput}
        views={views}
        format={format}
      />
    </LocalizationProvider>
  );
}

export default DatePickerComponent;
