import { FormControl, FormLabel, Typography } from "@mui/material";
import DatePickerComponent from "../DatePicker";

function DatePickerRangeComponent({ onDateChange, startDate, endDate }) {
  const handleStartDateChange = (date) => {
    onDateChange(date, endDate);
  };

  const handleEndDateChange = (date) => {
    onDateChange(startDate, date);
  };
  return (
    <>
      <FormControl>
        <FormLabel
          className="MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-colorPrimary"
          htmlFor="search_key"
          id="search_key-labe"
          sx={{
            position: "absolute",
            display: "block",
            transformOrigin: "left top",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "133%",
            top: 0,
            left: 0,
            transform: "translate(0px, -1.5px) scale(0.75)",
            transition:
              "color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
          }}
        >
          開始時間
        </FormLabel>
        <DatePickerComponent
          currentDate={startDate}
          onDateChange={handleStartDateChange}
          margintop={true}
        />
      </FormControl>
      <Typography
        sx={{
          marginLeft: "16px",
          fontSize: "18px",
        }}
      >
        ~
      </Typography>
      <FormControl sx={{ marginLeft: "16px" }}>
        <FormLabel
          className="MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-colorPrimary"
          htmlFor="search_key"
          id="search_key-labe"
          sx={{
            position: "absolute",
            display: "block",
            transformOrigin: "left top",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "133%",
            top: 0,
            left: 0,
            transform: "translate(0px, -1.5px) scale(0.75)",
            transition:
              "color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
          }}
        >
          終了時間
        </FormLabel>
        <DatePickerComponent
          currentDate={endDate}
          onDateChange={handleEndDateChange}
          margintop={true}
        />
      </FormControl>
    </>
  );
}

export default DatePickerRangeComponent;
