import { AddToPhotosOutlined } from "@mui/icons-material";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import {
  AppBar,
  Box,
  Button,
  Container,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Modal,
  SvgIcon,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import "./index.css";
import { useEffect, useState } from "react";
import PopupFillerMaster from "../PopupFillerMaster";
import PopupGenreMaster from "../PopupGenreMaster";
import { Controller, useForm, useWatch } from "react-hook-form";
import { getListGenres } from "../../api/genres";
import { useQuery } from "react-query";
import { STALE_TIME_DEFAULT } from "../../constants";
import { getListInputter } from "../../api/inputter";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  createStaffSchedule,
  deleteStaffSchedule,
  updateStaffSchedule,
} from "../../api/staffSchedule";
import dayjs from "dayjs";
import { handleSetErrorToField } from "../../utils";

const defaultValue = {
  title: "",
  start_time: undefined,
  inputter_id: "",
  genre_id: "",
  notification_time: 0,
  remarks: "",
  is_all_day: true,
};

function PopupEventComponent({
  handleClose,
  dateSelected,
  item,
  refetchStaffSchedule,
}) {
  const [fillerModalIsOpen, setFillerModalIsOpen] = useState(false);
  const [genreModalIsOpen, setGenreModalIsOpen] = useState(false);

  const [genresList, setGenresList] = useState([]);
  const [inputterList, setInputterList] = useState([]);

  const {
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
  });

  const id = useWatch({ control, name: "id", defaultValue: "" });
  const title = useWatch({ control, name: "title", defaultValue: "" });
  const inputterId = useWatch({
    control,
    name: "inputter_id",
    defaultValue: "",
  });
  const genreId = useWatch({ control, name: "genre_id", defaultValue: "" });
  const startTime = useWatch({
    control,
    name: "start_time",
    defaultValue: null,
  });
  const notificationTime = useWatch({
    control,
    name: "notification_time",
    defaultValue: 0,
  });
  const remarks = useWatch({ control, name: "remarks", defaultValue: "" });
  const isAllDay = useWatch({
    control,
    name: "is_all_day",
    defaultValue: false,
  });

  const { data: dataGenres, refetch: refetchGenres } = useQuery(
    ["list_all_genres"],
    () => {
      return getListGenres({
        per_page: 300,
      });
    },
    {
      keepPreviousData: true,
      staleTime: STALE_TIME_DEFAULT,
    }
  );

  const { data: dataInputter, refetch: refetchInputter } = useQuery(
    ["list_all_inputter"],
    () => {
      return getListInputter({
        per_page: 300,
      });
    },
    {
      keepPreviousData: true,
      staleTime: STALE_TIME_DEFAULT,
    }
  );

  const refetchAllData = () => {
    refetchInputter();
    refetchGenres();
    refetchStaffSchedule();
  };

  useEffect(() => {
    try {
      if (dataInputter) {
        setInputterList(dataInputter?.data?.data);
      }
    } catch (error) {}
  }, [dataInputter]);

  useEffect(() => {
    try {
      if (dataGenres) {
        setGenresList(dataGenres?.data?.data);
      }
    } catch (error) {}
  }, [dataGenres]);

  useEffect(() => {
    if (item) {
      const detail = item.detail;
      reset({
        id: detail?.id || "",
        title: detail?.title || "",
        start_time: detail?.start_time
          ? dayjs(detail.start_time)
          : dayjs(dateSelected),
        inputter_id: detail?.inputter?.id || "",
        genre_id: detail?.genre?.id || "",
        notification_time: detail?.notification_time || "",
        remarks: detail?.remarks || "",
        is_all_day: !!detail?.is_all_day,
      });
    } else {
      reset({
        ...defaultValue,
        start_time: dayjs(dateSelected),
      });
    }
  }, [item, dateSelected, reset]);

  const handleCreateRecord = async () => {
    try {
      const params = {
        title,
        start_time: isAllDay ? dayjs(startTime).format("YYYY-MM-DD 00:00:00") : dayjs(startTime).format("YYYY-MM-DD HH:mm:ss"),
        inputter_id: inputterId,
        genre_id: genreId,
        notification_time: notificationTime,
        remarks,
        is_all_day: isAllDay ? 1 : 0,
      };

      // eslint-disable-next-line no-restricted-globals
      if (confirm("スケジュール内容を保存しますか？")) {
        const res = await createStaffSchedule(params);

        alert(res?.message || "Create success!");
        reset(defaultValue);
        refetchStaffSchedule();
        handleClose();
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 422) {
          if (error.response.data.message) {
            const messages = error.response.data.message;
            if (typeof messages === "object") {
              handleSetErrorToField(messages, setError);
              return;
            }
          }
        } else if (error.response.status === 406) {
          if (error.response.data.message) {
            const messages = error.response.data.message;
            alert(messages || "エラーが発生しました。");
          }
        }
      }
    }
  };

  const handleUpdateRecord = async () => {
    try {
      // eslint-disable-next-line no-restricted-globals
      if (id && confirm("スケジュールを変更しますか？")) {
        const params = {
          title,
          start_time: isAllDay ? dayjs(startTime).format("YYYY-MM-DD 00:00:00") : dayjs(startTime).format("YYYY-MM-DD HH:mm:ss"),
          inputter_id: inputterId,
          genre_id: genreId,
          notification_time: notificationTime,
          remarks,
          is_all_day: isAllDay ? 1 : 0,
        };
        const res = await updateStaffSchedule(id, params);

        alert(res?.message || "Update success!");
        reset(defaultValue);
        refetchStaffSchedule();
        handleClose();
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 422) {
          if (error.response.data.message) {
            const messages = error.response.data.message;
            if (typeof messages === "object") {
              handleSetErrorToField(messages, setError);
              return;
            }
          }
        } else if (error.response.status === 406) {
          if (error.response.data.message) {
            const messages = error.response.data.message;
            alert(messages || "エラーが発生しました。");
          }
        }
      }
    }
  };

  const handleDeleteRecord = async () => {
    try {
      // eslint-disable-next-line no-restricted-globals
      if (id && confirm("スケジュールを削除しますか？")) {
        const res = await deleteStaffSchedule(id);
        alert(res?.message || "Delete success!");
        reset(defaultValue);
        refetchStaffSchedule();
        handleClose();
      }
    } catch (error) {
      alert("Delete error!");
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
          border: "5px solid #d7d0c5",
          borderRadius: "10px",
          boxShadow: "24",
          maxHeight: "100%",
          overflow: "auto",
          padding: "0 0 6",
          width: "70%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          position: "absolute",
        }}
      >
        <AppBar
          position="static"
          elevation={4}
          sx={{
            backgroundColor: "rgb(136, 160, 185)",
          }}
        >
          <Toolbar>
            <Typography variant="h6">スタッフスケジュール</Typography>
            <Button
              color="primary"
              style={{ marginLeft: "auto" }}
              onClick={handleClose}
            >
              <SvgIcon
                component={CancelPresentationIcon}
                style={{ color: "white", fontSize: 48 }}
              />
            </Button>
          </Toolbar>
        </AppBar>

        <Container
          maxWidth="md"
          sx={{
            sm: {
              paddingLeft: "24px",
              paddingRight: "24px",
            },
            width: "100%",
            marginTop: "16px",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
            <form>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="タイトル"
                    placeholder="タイトル"
                    required
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.title}
                    helperText={errors.title ? errors.title.message : ""}
                  />
                )}
              />

              <div className="field-allday">
                <Controller
                  name="is_all_day"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      }
                      label="終日"
                      labelPlacement="bottom"
                    />
                  )}
                />

                {!isAllDay && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <Controller
                      name="start_time"
                      control={control}
                      render={({ field }) => (
                        <DateTimePicker
                          label="開始予約時間"
                          value={field.value}
                          onChange={(date) => field.onChange(date)}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      )}
                    />
                    <FormHelperText sx={{ color: "red" }}>
                      {errors?.start_time?.message}
                    </FormHelperText>
                  </Box>
                )}
              </div>

              <div className="field-enteredBy">
                <Controller
                  name="inputter_id"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        label="入力者"
                        select
                        fullWidth
                        {...field}
                        variant="outlined"
                        margin="normal"
                      >
                        {inputterList.map((item, index) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Button
                        variant="outlined"
                        color="success"
                        style={{ marginTop: "16px", marginLeft: "24px" }}
                        onClick={() => setFillerModalIsOpen(true)}
                      >
                        <AddToPhotosOutlined />
                      </Button>
                    </>
                  )}
                />
              </div>
              {errors.inputter_id && (
                <div>
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.inputter_id?.message}
                  </FormHelperText>
                </div>
              )}

              <div className="field-enteredBy">
                <Controller
                  name="genre_id"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        label="ジャンル"
                        select
                        fullWidth
                        {...field}
                        variant="outlined"
                        margin="normal"
                      >
                        {genresList.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Button
                        variant="outlined"
                        color="success"
                        style={{ marginTop: "16px", marginLeft: "24px" }}
                        onClick={() => setGenreModalIsOpen(true)}
                      >
                        <AddToPhotosOutlined />
                      </Button>
                    </>
                  )}
                />
              </div>
              {errors.genre_id && (
                <div>
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.genre_id?.message}
                  </FormHelperText>
                </div>
              )}

              <Controller
                name="notification_time"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="お知らせ"
                    type="number"
                    placeholder="なし"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    {...field}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">分前</InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (!/^\d*$/.test(value)) {
                        e.target.value = value.replace(/[^\d]/g, "");
                      }
                      field.onChange(e);
                    }}
                    error={!!errors.notification_time}
                    helperText={
                      errors.notification_time
                        ? errors.notification_time.message
                        : ""
                    }
                  />
                )}
              />

              <Controller
                name="remarks"
                control={control}
                render={({ field }) => (
                  <textarea
                    placeholder="メモ"
                    {...field}
                    style={{
                      marginTop: "30px",
                      height: "96px",
                      width: "100%",
                      fontSize: "18px",
                      outline: "none",
                      background: "transparent",
                      borderRadius: "5px",
                      resize: "none",
                    }}
                  />
                )}
              />

              <Box sx={{ paddingTop: "16px", paddingBottom: "16px" }}>
                {!id ? (
                  <Box>
                    <Button
                      type="button"
                      variant="outlined"
                      color="success"
                      size="large"
                      fullWidth
                      style={{ marginTop: "40px" }}
                      onClick={handleCreateRecord}
                    >
                      スケジュール確定
                    </Button>
                  </Box>
                ) : (
                  <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                    <Button
                      type="button"
                      variant="outlined"
                      color="secondary"
                      onClick={handleUpdateRecord}
                    >
                      編集
                    </Button>
                    <Button
                      type="button"
                      variant="outlined"
                      color="error"
                      onClick={handleDeleteRecord}
                    >
                      削除
                    </Button>
                  </Box>
                )}
              </Box>
            </form>
          </LocalizationProvider>
        </Container>
      </Box>

      <Modal
        open={fillerModalIsOpen}
        onClose={() => setFillerModalIsOpen(false)}
        aria-labelledby="transition-modal-title"
      >
        <PopupFillerMaster
          onClose={() => setFillerModalIsOpen(false)}
          reftchList={refetchAllData}
        />
      </Modal>
      <Modal
        open={genreModalIsOpen}
        onClose={() => setGenreModalIsOpen(false)}
        aria-labelledby="transition-modal-title"
      >
        <PopupGenreMaster
          onClose={() => setGenreModalIsOpen(false)}
          reftchList={refetchAllData}
        />
      </Modal>
    </>
  );
}

export default PopupEventComponent;
