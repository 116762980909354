export const getCost = (list = [], id) => {
  try {
    const obj = list.find((item) => item.id === id);
    if (!obj) return 0;
    return Number(obj?.cost) || 0;
  } catch (error) {
    return 0;
  }
};

export const getDuration = (list = [], id, key) => {
  try {
    const obj = list.find((item) => item.id === id);
    if (!obj) return 0;
    return obj?.[key] || 0;
  } catch (error) { }
};

export const handleSetErrorToField = (objError, setError) => {
  for (let key in objError) {
    setError(key, {
      type: "manual",
      message: objError[key],
    });
  }
};

export const getCountOrderNominatation = (list, id) => {
  const nomination = list.find((item) => item.id === id);
  return nomination?.count_order || 0;
};

export const getValueBoolean = (value) => {
  return value ? "〇" : "×";
};

export const formatJapaneseDate = (date) => {
  const d = new Date(date);
  return `${d.getMonth() + 1}月${d.getDate()}日`;
};

export const formatCurrency = (value) => {
  const valueInt = parseInt(value, 10);

  if (isNaN(valueInt)) {
    return "0円";
  }

  return `${valueInt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}円`;
};

export const formatNumberWithUnit = (value, unit) => {
  const valueInt = parseInt(value, 10);

  if (isNaN(valueInt)) {
    return `0${unit}`;
  }

  return `${valueInt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${unit}`;
};

export const isNumeric = (value) => {
  return !isNaN(value) && !isNaN(parseFloat(value));
};

export const getByKey = (list = [], id, key) => {
  try {
    const obj = list.find((item) => item.id === id);
    if (!obj) return 0;
    return Number(obj?.[key]) || 0;
  } catch (error) {
    return 0;
  }
}
