import React, { useEffect, useState } from "react";
import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import usePaginate from "../../hooks/usePaginate";
import { useQuery } from "react-query";
import { getListActivityLog } from "../../api/activityLog";
import TablePaginationLayout from "../../layouts/TablePagination";
import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { isNumeric } from "../../utils";

const colums = [
  { label: "id", value: "id", width: 5, sorted: true },
  {
    label: "内容",
    value: "content",
    width: 55,
    sorted: false,
    alignContent: "left",
  },
  {
    label: "著者名",
    value: "author",
    width: 20,
    sorted: false,
  },
  {
    label: "時間",
    value: "created_at",
    width: 20,
    sorted: true,
  },
];

const ActivityLog = () => {
  const { page, perPage, total, changePage, setTotal, changePerPage } =
    usePaginate();

  const [logs, setLogs] = useState([]);
  const [dataSorted, setDataSorted] = useState([]);
  const [orderBy, setOrderBy] = useState("id");
  const [order, setOrder] = useState("desc");

  const { data } = useQuery(
    ["log_activities", page, perPage],
    () => {
      const params = {
        page: page + 1,
        per_page: perPage,
      };
      return getListActivityLog(params);
    },
    {
      keepPreviousData: true,
    }
  );

  const handleChangeOrderBy = (key) => {
    const isSorted = orderBy === key;
    let direction = isSorted
      ? order === "asc"
        ? "desc"
        : "asc"
      : "desc";
    dataSorted.sort((a, b) => {
      let aValue = a[key];
      let bValue = b[key];

      // if aValue and bValue are numberic, sort parseInt
      if (isNumeric(aValue) && isNumeric(bValue)) {
        aValue = parseInt(aValue);
        bValue = parseInt(bValue);
      }

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setDataSorted([...dataSorted]);
    setOrderBy(key);
    setOrder(direction);
  };

  useEffect(() => {
    try {
      if (data) {
        setLogs(data.data.data);
        setTotal(data.data.total);
      }
    } catch (error) { }
  }, [data, setTotal]);

  useEffect(() => {
    setDataSorted(logs.sort((a, b) => b.id - a.id));
  }, [logs]);

  return (
    <div>
      <HeaderReservationManagement />
      <Navbar />
      <Container maxWidth="lg">
        <Box sx={{ marginTop: "40px", maxWidth: "100%" }}>
          <Typography
            component="p"
            sx={{
              fontSize: {
                xs: 20,
                md: 28,
              },
              color: "#fff",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "normal",
              lineHeight: "24px",
              letterSpacing: "3px",
              margin: "80px 0px 16px",
              textDecoration: "none",
              width: "100%",
              textAlign: "left",
            }}
          >
            【登録・変更履歴のロ】
          </Typography>
          <Paper
            sx={{
              marginTop: "16px",
              backgroundColor: "#fff",
              boxShadow: "none",
            }}
          >
            <TableContainer>
              <Table aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    {colums.map((item) => {
                      if (!item.sorted)
                        return (
                          <TableCell
                            key={item.value}
                            align="center"
                            sx={{ padding: "8px 16px", color: "#000" }}
                            width={item.width}
                          >
                            {item.label}
                          </TableCell>
                        );

                      const columnSortActive = item.value === orderBy;

                      return (
                        <TableCell
                          key={item.value}
                          align="center"
                          sx={{ padding: "8px 16px", color: "#000" }}
                          width={item.width}
                          sortDirection={columnSortActive ? order : false}
                        >
                          <TableSortLabel
                            active={columnSortActive}
                            direction={columnSortActive ? order : "asc"}
                            onClick={() => handleChangeOrderBy(item.value)}
                          >
                            {item.label}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataSorted.map((rowData) => (
                    <TableRow key={rowData.id} className="MuiTableRow-hover">
                      {colums.map((item) => (
                        <TableCell
                          key={item.value}
                          align={item.alignContent ?? "center"}
                          sx={{ color: "#000" }}
                        >
                          {rowData[item.value]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                  {dataSorted.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={colums.length} align="center">
                        <Typography>該当データがありません。</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationLayout
              rowsPerPage={perPage}
              total={total}
              page={page}
              rowsPerPageOptions={[50, 100, 125]}
              onPageChange={changePage}
              handleChangeRowsPerPage={changePerPage}
            />
          </Paper>
        </Box>
      </Container>
    </div>
  );
};

export default ActivityLog;
