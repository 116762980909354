import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import DatePickerComponent from "../../components/DatePicker";
import MonthlyReverseCountComponent from "../../components/MonthlyReverseCount";
import TablePaginationLayout from "../../layouts/TablePagination";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import TitleComponent from "../../components/Title";
import "./index.css";
import ModalReservationRegister from "../../features/reservation/ModalReservationRegister";
import { useQuery } from "react-query";
import {
  downBottomOrderNumber,
  downOrderNumber,
  getCalendarByDate,
  upOrderNumber,
  upTopOrderNumber,
} from "../../api/therapist";
import usePaginate from "../../hooks/usePaginate";
import TooltipOrder from "../../features/reservation/TooltipOrder";
import { getListPaymentMethod } from "../../api/paymentMethod";
import { getListOption } from "../../api/option";
import { getListExtension } from "../../api/extension";
import { getListCourse } from "../../api/course";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { getCountOrders } from "../../api/orders";

const pixelPer10Minutes = 15;
const pixel1Hour = pixelPer10Minutes * 6;

const styleHead = {
  display: "block",
  width: "100px",
  border: "1px solid #e0e0e0",
  textAlign: "center",
  verticalAlign: "middle",
  padding: "auto",
};

const styleSticky = {
  width: "90px",
  display: "block",
  position: "sticky",
  zIndex: 5,
  left: 0,
  backgroundColor: "#fff",
  border: "2px solid #e0e0e0",
};

const styleIcon = {
  color: "#ED6C02",
  cursor: "pointer",
};

function ReservationManagement() {
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [currentDate, setCurrentDate] = useState(dayjs(new Date()));
  const [activeDayIndex, setActiveDayIndex] = useState(null);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [orderSelected, setOrderSelected] = useState({});

  const { page, perPage, total, changePage, setTotal, changePerPage } =
    usePaginate();
  const [calendarList, setCalendarList] = useState([]);

  const updateCurrentDate = (newDate) => {
    const updatedDate = currentDate.set("date", newDate);
    setCurrentDate(updatedDate);
  };

  const hours = useMemo(() => {
    const hoursArray = [];
    for (let i = 10; i < 29; i++) {
      const hour = dayjs()
        .hour(i % 24)
        .minute(0)
        .format("H:mm");
      hoursArray.push(hour);
    }
    return hoursArray;
  }, []);

  const { data: dataCalendar, refetch: refetchCalendar } = useQuery(
    ["calendar_by_date", currentDate],
    () => {
      const payload = {
        date: currentDate.format("YYYY-MM-DD"),
      };
      return getCalendarByDate(payload);
    },
    {
      keepPreviousData: true,
    }
  );

  const { data: dataCountOrders, refetch: refetchCountOrders } = useQuery(
    ["count_order_by_date", currentDate],
    () => {
      const payload = {
        // start of month
        start_date: currentDate.startOf("month").format("YYYY-MM-DD"),
        // end of month
        end_date: currentDate.endOf("month").format("YYYY-MM-DD"),
      };
      return getCountOrders(payload);
    },
    {
      keepPreviousData: true,
    }
  );

  const { data: dataCourse } = useQuery(
    ["list_course"],
    () => {
      return getListCourse({ per_page: 10000 });
    },
    {
      keepPreviousData: true,
    }
  );

  const { data: dataPaymentMethod } = useQuery(
    ["list_payment_method"],
    () => {
      return getListPaymentMethod({ per_page: 10000 });
    },
    {
      keepPreviousData: true,
    }
  );

  const { data: dataOption } = useQuery(
    ["list_option"],
    () => {
      return getListOption({ per_page: 10000 });
    },
    {
      keepPreviousData: true,
    }
  );

  const { data: dataExtension } = useQuery(
    ["list_extension"],
    () => {
      return getListExtension({ per_page: 10000 });
    },
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    try {
      if (dataCalendar) {
        const { data, total } = dataCalendar.data;
        setCalendarList(data);
        setTotal(total);
      }
    } catch (error) { }
  }, [dataCalendar, setTotal]);

  useEffect(() => {
    const currentMonth = currentDate.month();
    const currentYear = currentDate.year();
    const daysInCurrentMonth = dayjs(
      `${currentYear}-${currentMonth + 1}-01`
    ).daysInMonth();

    const daysArray = [];
    for (let i = 1; i <= daysInCurrentMonth; i++) {
      const date = dayjs(`${currentYear}-${currentMonth + 1}-${i}`).format(
        "YYYY-MM-DD"
      );
      // find reserve count
      const reserveCount = dataCountOrders?.data?.find(
        (item) => item.date === date
      );
      daysArray.push({
        dayOfWeek: dayjs(date).day(),
        isActive: false,
        reserveCount: reserveCount?.total_orders || 0,
        date: i < 10 ? `0${i}` : `${i}`,
        dateFormat: date,
      });
    }
    setDaysInMonth(daysArray);
  }, [dataCountOrders, currentDate]);

  useEffect(() => {
    const todayIndex = daysInMonth.findIndex(
      (day) => parseInt(day.date) === currentDate.date()
    );
    if (todayIndex !== -1) {
      setActiveDayIndex(todayIndex);
    }
  }, [currentDate, daysInMonth]);

  const renderShift = (calendar) => {
    const startShift = dayjs(calendar.calendar_date_start, "YYYY-MM-DD HH:mm:ss");
    const endShift = dayjs(calendar.calendar_date_end, "YYYY-MM-DD HH:mm:ss");

    const startOfDay = dayjs(calendar.calendar_date_start, "YYYY-MM-DD HH:mm:ss")
      .startOf("day")
      .add(10, "hour");

    const startMinutes = startShift.diff(startOfDay, "minute");
    const endMinutes = endShift.diff(startOfDay, "minute");

    const minutesDifference = endMinutes - startMinutes;

    const totalWidth = (minutesDifference / 10) * pixelPer10Minutes;
    const diff = (startMinutes / 10) * pixelPer10Minutes;

    const count = Math.floor(minutesDifference / 10);

    return (
      <div
        style={{
          height: "100%",
          width: `${totalWidth}px`,
          position: "absolute",
          zIndex: 3,
          left: `${diff}px`,
          background: "#fff",
          display: "flex",
          border: "2px solid #fff",
        }}
      >
        {Array.from({ length: count }, (_, index) => (
          <button
            key={index}
            style={{
              height: "100%",
              width: `${pixelPer10Minutes}px`,
              border: "1px dotted #000",
            }}
            className="hover-cloud"
            onClick={() => {
              const start = startShift.add(10 * index, "minute");
              setOrderSelected({
                therapist_id: calendar.id,
                store_id: calendar.store_id,
                therapist_name: calendar.name,
                date: currentDate.format("YYYY-MM-DD"),
                date_start: start.format("YYYY-MM-DD HH:mm:ss"),
              });
              setIsOpenModal(true);
            }}
          ></button>
        ))}
        {count * pixelPer10Minutes < totalWidth && (
          <div
            style={{
              height: "100%",
              width: `${totalWidth - count * pixelPer10Minutes}px`,
              border: "1px dotted #000",
            }}
          ></div>
        )}
      </div>
    );
  };

  const renderOrder = (calendar, order, index) => {
    const startDate = dayjs(order.date_start, "YYYY-MM-DD HH:mm:ss");
    const endDate = dayjs(order.date_end, "YYYY-MM-DD HH:mm:ss");

    const startMinutes =
      startDate.subtract(10, "hour").hour() * 60 + startDate.minute();

    const minutesDifference = endDate.diff(startDate, "minute");

    const totalWidth = (minutesDifference / 10) * pixelPer10Minutes;
    const diff = (startMinutes / 10) * pixelPer10Minutes;

    return (
      <Tooltip title={<TooltipOrder order={order} />}>
        <div
          style={{
            height: "100%",
            width: `${totalWidth}px`,
            boxSizing: "border-box",
            position: "absolute",
            zIndex: 4,
            left: `${diff}px`,
            padding: "2px",
          }}
          className="order-item"
        >
          <button
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              background: "#e1f5eb",
              border: "2px solid #85d6ae",
              overflowY: "auto",
            }}
            onClick={() => {
              setOrderSelected({
                ...order,
                therapist_id: calendar.id,
                store_id: calendar.store_id,
                therapist_name: calendar.name,
                course_payment_method_id: order.course_payment_method_id,
                extension_id:
                  order.extensions?.length > 0
                    ? order.extensions[0]?.extension_id
                    : "",
                extension_payment_method_id:
                  order.extensions.length > 0
                    ? order.extensions[0]?.payment_method_id
                    : "",
                option_id:
                  order.options?.length > 0 ? order.options[0]?.option_id : "",
                option_payment_method_id:
                  order.options.length > 0
                    ? order.options[0]?.payment_method_id
                    : "",
              });
              setIsOpenModal(true);
            }}
          >
            <div style={{ marginBottom: '3px' }}>
              <span>{order.customer_name}</span>
              {order?.nomination?.id && <span
                style={{
                  color: order?.nomination?.color,
                  background: order?.nomination?.background ? order?.nomination?.background : 'none',
                  borderColor: order?.nomination?.background ? order?.nomination?.background : order?.nomination?.color,
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  padding: '4px 8px',
                  borderRadius: '5px',
                  marginLeft: '5px'
                }}
              >
                {order?.nomination?.name}
              </span>}
            </div>
            <span>({order.course?.name})</span>
          </button>
        </div>
      </Tooltip>
    );
  };

  const handleUpOrderNumber = async (id) => {
    try {
      const response = await upOrderNumber(id);
      refetchCalendar();
      alert(response?.message);
    } catch (error) {
      alert(error?.response?.data?.message || "エラーが発生しました。");
    }
  };

  const handleDownOrderNumber = async (id) => {
    try {
      const response = await downOrderNumber(id);
      refetchCalendar();
      alert(response?.message);
    } catch (error) {
      alert(error?.response?.data?.message || "エラーが発生しました。");
    }
  };
  const handleUpTopOrderNumber = async (id) => {
    try {
      const response = await upTopOrderNumber(id);
      refetchCalendar();
      alert(response?.message);
    } catch (error) {
      alert(error?.response?.data?.message || "エラーが発生しました。");
    }
  };
  const handleDownBottomOrderNumber = async (id) => {
    try {
      const response = await downBottomOrderNumber(id);
      refetchCalendar();
      alert(response?.message);
    } catch (error) {
      alert(error?.response?.data?.message || "エラーが発生しました。");
    }
  };

  return (
    <>
      <HeaderReservationManagement />
      <Navbar />
      <Container
        maxWidth="md"
        sx={{
          marginTop: {
            xs: 2,
            md: 5,
          },
        }}
      >
        <TitleComponent valueText={"予約管理"} />
      </Container>
      <Container
        maxWidth="xl"
        sx={{
          paddingLeft: {
            sm: 3,
          },
          paddingRight: {
            sm: 3,
          },
        }}
      >
        <Box
          sx={{
            marginTop: "40px",
            paddingRight: "16px",
            paddingLeft: "16px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <Button
                variant="outlined"
                onClick={() =>
                  setCurrentDate((prev) => prev.subtract(1, "day"))
                }
              >
                前日
              </Button>
            </Grid>
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <FormControl>
                <DatePickerComponent
                  currentDate={currentDate}
                  onDateChange={setCurrentDate}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <Button
                variant="outlined"
                onClick={() => setCurrentDate((prev) => prev.add(1, "day"))}
              >
                前日
              </Button>
            </Grid>
          </Grid>
        </Box>
        <MonthlyReverseCountComponent
          days={daysInMonth}
          currentDate={currentDate}
          updateCurrentDate={updateCurrentDate}
          activeDayIndex={activeDayIndex}
          setActiveDayIndex={setActiveDayIndex}
          type={"reservation"}
        />
      </Container>
      <Container
        maxWidth="xl"
        sx={{
          paddingLeft: {
            sm: 3,
          },
          paddingRight: {
            sm: 3,
          },
        }}
      >
        <Box sx={{ marginTop: "40px" }}>
          <Paper
            sx={{
              backgroundColor: "rgb(255, 255, 255)",
              color: "rgba(0, 0, 0, 0.87)",
              transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              borderRadius: "4px",
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
              width: "100%",
              marginBottom: "16px",
              marginTop: "64px",
              overflow: "auto",
            }}
            className="reservationTable"
          >
            <TableContainer component={Paper}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ display: "flex" }}>
                      <TableCell sx={{ ...styleHead, width: "90px" }}>
                        #
                      </TableCell>
                      <TableCell sx={styleHead}>店舗</TableCell>
                      <TableCell sx={styleHead}>名前</TableCell>
                      {hours.map((hour) => (
                        <TableCell
                          key={hour}
                          align="center"
                          sx={{
                            border: "2px solid #e0e0e0",
                            width: "90px",
                            padding: "auto",
                          }}
                        >
                          {hour}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {calendarList.map((calendar, calendarIndex) => (
                      <TableRow
                        key={calendar.id}
                        sx={{ display: "flex", padding: 0 }}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            ...styleSticky,
                            padding: "12px",
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gridTemplateRows: "1fr 1fr",
                            gap: "4px",
                          }}
                        >
                          <KeyboardArrowUpIcon
                            sx={styleIcon}
                            onClick={() => handleUpOrderNumber(calendar.calendar_id)}
                          />
                          <KeyboardDoubleArrowUpIcon
                            sx={styleIcon}
                            onClick={() => handleUpTopOrderNumber(calendar.calendar_id)}
                          />
                          <KeyboardArrowDownIcon
                            sx={styleIcon}
                            onClick={() => handleDownOrderNumber(calendar.calendar_id)}
                          />
                          <KeyboardDoubleArrowDownIcon
                            sx={styleIcon}
                            onClick={() =>
                              handleDownBottomOrderNumber(calendar.calendar_id)
                            }
                          />
                          {/* {calendar.id} */}
                        </TableCell>
                        <TableCell
                          sx={{
                            ...styleSticky,
                            left: "90px",
                            border: "2px solid #e0e0e0",
                            width: "100px",
                            background: "#fff",
                          }}
                        >
                          {calendar.store_name}
                        </TableCell>
                        <TableCell
                          sx={{
                            ...styleSticky,
                            left: "190px",
                            border: "2px solid #e0e0e0",
                            width: "100px",
                            background: "#fff",
                          }}
                        >
                          {calendar?.name}
                        </TableCell>
                        <TableCell
                          sx={{
                            width: pixel1Hour * 19 + "px",
                            padding: 0,
                            backgroundColor: "#808080",
                            position: "relative",
                            zIndex: 2,
                          }}
                        >
                          {renderShift(calendar)}
                          {calendar.orders.map((order, orderIndex) => {
                            return renderOrder(calendar, order, calendarIndex + orderIndex);
                          })}
                        </TableCell>
                      </TableRow>
                    ))}
                    {calendarList.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={hours.length + 3} align="center">
                          <Typography>該当データがありません。</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </TableContainer>

            <TablePaginationLayout
              rowsPerPage={perPage}
              total={total}
              page={page}
              rowsPerPageOptions={[50, 100, 125]}
              onPageChange={changePage}
              handleChangeRowsPerPage={changePerPage}
            />
          </Paper>
        </Box>
      </Container>
      <ModalReservationRegister
        open={isOpenModal}
        handleClose={() => {
          setIsOpenModal(false);
          setOrderSelected({});
        }}
        item={orderSelected}
        courseOptions={dataCourse?.data?.data || []}
        extensionOptions={dataExtension?.data?.data || []}
        paymentMethodOptions={dataPaymentMethod?.data?.data?.sort((a, b) => a.id - b.id) || []}
        optionOptions={dataOption?.data?.data || []}
        refetch={() => {
          refetchCalendar();
          refetchCountOrders();
        }}
      />
    </>
  );
}

export default ReservationManagement;
