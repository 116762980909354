import React from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Box, Container, Paper } from "@mui/material";
import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import TitleComponent from "../../components/Title";
import "../../App.css"; // Tạo một file CSS để thêm các class tùy chỉnh nếu cần
import CalendarComponent from "../../components/Calendar";

function StaffSchedulePage() {
  return (
    <>
      <HeaderReservationManagement />
      <Navbar />
      <Container
        maxWidth="md"
        sx={{
          marginTop: {
            xs: 2,
            md: 5,
          },
        }}
      >
        <TitleComponent valueText={"スタッフスケジュール"} />
      </Container>
      <Box className="calendar_container">
        <Paper
          sx={{
            marginTop: {
              xs: 3,
              md: 10,
            },
            paddingTop: "64px",
            paddingBottom: "64px",
            marginBottom: "80px",
          }}
          className="calendar_paper_container"
        >
          <CalendarComponent />
        </Paper>
      </Box>
    </>
  );
}

export default StaffSchedulePage;
