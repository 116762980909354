import { sendDelete, sendGet, sendPost, sendPut } from "./axios";

export const getListStaffSchedule = (params) =>
  sendGet("/staff-schedule", params);

export const createStaffSchedule = (params) =>
  sendPost("/staff-schedule", params);

export const updateStaffSchedule = (id, params) =>
  sendPut(`/staff-schedule/${id}`, params);

export const deleteStaffSchedule = (id) => sendDelete(`/staff-schedule/${id}`);
