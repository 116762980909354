import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  styled,
} from "@mui/material";
import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import NavButton from "../../layouts/NavButton";
import useEventImageMaster from "../../features/eventImage";
import ImageUploader from "../../components/ImageUploader";

const CustomSaveButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.success.main,
  color: theme.palette.success.main,
  backgroundColor: 'transparent',
  width: '100%',
  maxWidth: '200px',
  '&:hover': {
    backgroundColor: theme.palette.success.light,
    borderColor: theme.palette.success.main,
  },
}));

const AccountMasterEventImage = () => {
  const {
    setEventImageFile,
    eventImageSrc,
    setEventImageSrc,
    eventImageError,
    setEventImageError,
    handleUpdateEventImage,
  } = useEventImageMaster()

  return (
    <>
      <HeaderReservationManagement />
      <Navbar />
      <Container maxWidth="lg">
        <NavButton />
        <Typography
          component="p"
          sx={{
            fontSize: {
              xs: 20,
              md: 28,
            },
            color: "#fff",
            top: "104px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "24px",
            letterSpacing: "3px",
            margin: "80px 0px 16px",
            textDecoration: "none",
            width: "100%",
            paddingBottom: "15px",
            borderBottom: "2px solid white",
            textAlign: "left",
          }}
        >
          イベント追加
        </Typography>
        <Box sx={{ marginTop: "40px", maxWidth: "100%" }}>
          <Paper sx={{ padding: "16px", backgroundColor: 'transparent', boxShadow: 'none' }}>
            <Grid container xs={12} spacing={2} direction="column">
              <Grid item xs={12}>
                <ImageUploader
                  setImageFile={setEventImageFile}
                  imageSrc={eventImageSrc}
                  setImageSrc={setEventImageSrc}
                  imageError={eventImageError}
                  setImageError={setEventImageError}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <CustomSaveButton
                  variant="outlined"
                  onClick={handleUpdateEventImage}
                >
                  保存
                </CustomSaveButton>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    </>
  );
}

export default AccountMasterEventImage;
