import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";

function NavigationSaleMGR() {
  return (
    <Grid
      container
      sx={{
        marginTop: "40px",
        justifyContent: "space-evenly",
      }}
    >
      <Grid item>
        <Button
          variant="outlined"
          style={{
            boxShadow: "rgb(0, 0, 0) 6px 8px 15px",
          }}
          sx={{
            border: "1px solid rgb(255, 255, 255)",
            color: "white",
          }}
          component={Link}
          to="/salesmgr_byday"
        >
          日別
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          style={{
            boxShadow: "rgb(0, 0, 0) 6px 8px 15px",
          }}
          sx={{
            border: "1px solid rgb(255, 255, 255)",
            color: "white",
          }}
          component={Link}
          to="/salesmgr_bymonth"
        >
          月別
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          style={{
            boxShadow: "rgb(0, 0, 0) 6px 8px 15px",
          }}
          sx={{
            border: "1px solid rgb(255, 255, 255)",
            color: "white",
          }}
          component={Link}
          to="/salesmgr_bytherapist"
        >
          セラピスト別
        </Button>
      </Grid>
    </Grid>
  );
}

export default NavigationSaleMGR;
