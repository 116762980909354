import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getEventImage, uploadEventImage } from "../../api/eventImage";

const useEventImageMaster = () => {
  const [eventImageFile, setEventImageFile] = useState(null);
  const [eventImageSrc, setEventImageSrc] = useState(null);
  const [eventImageError, setEventImageError] = useState("");

  const { data: eventImageData } = useQuery(
    ["EventImage"],
    () => {
      return getEventImage();
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (eventImageData) {
      setEventImageSrc(eventImageData?.data?.image);
    }
  }, [eventImageData]);

  const handleUpdateEventImage = async () => {
    try {
      const updatedData = new FormData();
      if (eventImageSrc === null) {
        updatedData.append("is_image_delete", true);
      }
      if (eventImageFile) {
        updatedData.append("image", eventImageFile);
        updatedData.append("is_image_delete", false);
      }
      const response = await uploadEventImage(updatedData);

      if (response && response.success) {
        setEventImageFile(null);
        setEventImageSrc(response?.data?.image);
        setEventImageError("");
        alert("更新しました。");
        return true
      } else {
        alert("エラーが発生しました。");
        return false
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const fieldErrors = error.response.data.message;
        // check if avatar error exists
        if (fieldErrors.avatar) {
          setEventImageError(fieldErrors.avatar);
        }
      }
      else {
        alert("エラーが発生しました。");
      }
      return false
    }
  };

  return {
    eventImageFile,
    setEventImageFile,
    eventImageSrc,
    setEventImageSrc,
    eventImageError,
    setEventImageError,
    handleUpdateEventImage,
  };
};

export default useEventImageMaster;
