import { sendDelete, sendGet, sendPost, sendPut } from "./axios";

export const getListInputter = (params) => sendGet("/inputters", params);

export const createInputter = (params) => sendPost("/inputters", params);

export const updateInputter = (id, params) =>
  sendPut(`/inputters/${id}`, params);

export const deleteInputter = (id) => sendDelete(`/inputters/${id}`);
