import * as React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { BarPlot } from "@mui/x-charts/BarChart";
import { LinePlot, MarkPlot } from "@mui/x-charts/LineChart";
import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
import {
  ChartContainer,
  ChartsGrid,
  ChartsTooltip,
  ChartsYAxis,
} from "@mui/x-charts";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";

function ChartComponent({ dataset }) {
  const [showAllSales, setShowAllSales] = React.useState(true);
  const [showPricePerCustomer, setShowPricePerCustomer] = React.useState(true);
  const [nominalSales, setNominalSales] = React.useState(true);

  const handleToggleAllSales = () => setShowAllSales(!showAllSales);
  const handleTogglePricePerCustomer = () =>
    setShowPricePerCustomer(!showPricePerCustomer);
  const handleToggleNominalSales = () => setNominalSales(!nominalSales);

  const formatYAxis = (value) => {
    if (value >= 1_000_000) {
      return `${(value / 1_000_000).toFixed(1)}M`;
    }
    if (value >= 1_000) {
      return `${(value / 1_000).toFixed(1)}K`;
    }
    return value;
  };

  return (
    <Paper sx={{ boxShadow: "none" }} elevation={3}>
      <Typography sx={{ textAlign: "center" }}>売上</Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <FormControlLabel
          control={
            <Checkbox checked={showAllSales} onChange={handleToggleAllSales} />
          }
          label="總売上"
          sx={{ "& .MuiFormControlLabel-label": { fontSize: "12px" } }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showPricePerCustomer}
              onChange={handleTogglePricePerCustomer}
            />
          }
          label="客単価"
          sx={{ "& .MuiFormControlLabel-label": { fontSize: "12px" } }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={nominalSales}
              onChange={handleToggleNominalSales}
            />
          }
          label="指名売上"
          sx={{ "& .MuiFormControlLabel-label": { fontSize: "12px" } }}
        />
      </Box>
      <ChartContainer
        width={1100}
        height={536}
        series={[
          showAllSales && {
            type: "bar",
            data: dataset.map((item) => item.total_price),
            label: "總売上",
            yAxisKey: "left-axis-id",
          },
          showPricePerCustomer && {
            type: "line",
            data: dataset.map((item) => item.avg_price_per_customer),
            label: "客単価",
            yAxisKey: "right-axis-id",
          },
          nominalSales && {
            type: "line",
            data: dataset.map((item) => item.nomination_fee),
            label: "指名売上",
            yAxisKey: "right-axis-id",
          },
        ].filter(Boolean)}
        xAxis={[
          {
            data: dataset.map((item) => item.month),
            scaleType: "band",
            id: "x-axis-id",

          },
        ]}
        yAxis={[
          {
            min: 0,
            id: "left-axis-id",
            valueFormatter: formatYAxis
          },
          {
            min: 0,
            id: "right-axis-id",
            position: "right",

          },
        ]}
        colors={["#ffb1c1", "#007bff", "#74b6fb"]}
        margin={{ left: 100 }}
      >
        <ChartsGrid vertical horizontal />
        <BarPlot />
        <LinePlot />
        <MarkPlot />
        <ChartsXAxis position="bottom" axisId="x-axis-id" />
        <ChartsYAxis
          axisId="left-axis-id"
        />
        <ChartsYAxis axisId="right-axis-id" position="right" />
        <ChartsTooltip />
      </ChartContainer>
    </Paper>
  );
}

export default ChartComponent;
