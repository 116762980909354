import {
  Backdrop,
  Box,
  ClickAwayListener,
  Container,
  Paper,
} from "@mui/material";
import { useCallback, useEffect, useRef } from "react";

function PopupComponent({
  open,
  onClose,
  children,
  width = 600,
}) {
  const popupRef = useRef(null);

  const handleClickOutside = useCallback(
    (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  if (!open) return null; // Return null if the popup is not open

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Backdrop
        invisible
        open
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 2,
        }}
      >
        <Paper
          sx={{
            maxHeight: "85vh",
            overflowY: "auto",
            position: "absolute",
            width: width,
            maxWidth: "90vw",
            backgroundColor: "rgb(255, 255, 255)",
            padding: "32px",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            border: "4px solid rgb(215, 208, 197)",
            borderRadius: "8px",
          }}
          ref={popupRef}
        >
          <Container>
            <Box component="form" noValidate autoComplete="off">
              {children}
            </Box>
          </Container>
        </Paper>
      </Backdrop>
    </ClickAwayListener>
  );
}

export default PopupComponent;
