import React from 'react';
import { Box, Button, IconButton, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const MAX_SIZE_MB = 10;
const VALID_FORMATS = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/webp'];

const ImageUploader = ({
  setImageFile = () => { },
  imageSrc = null,
  setImageSrc = () => { },
  error = "",
  setError = () => { },
  btnUploadText = '画像をアップロード',
  btnChangeText = '画像を変更',
  maxWidthContainer = '800px',
  maxWidthImage = '600px',
}) => {
  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      // Check file type
      if (!VALID_FORMATS.includes(file.type)) {
        setError('無効なファイル形式です。JPEG、PNG、JPG、GIF、WEBPのみが許可されています。');
        return;
      }

      // Check file size
      if (file.size > MAX_SIZE_MB * 1024 * 1024) {
        setError(`ファイルサイズが最大の${MAX_SIZE_MB}MBを超えています。`);
        return;
      }

      setError(null);
      setImageFile(file);

      // Convert file to Base64 for preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setImageFile(null);
    setImageSrc(null);
    setError("");
  };

  return (
    <Box
      sx={{
        border: '2px dashed rgba(0, 0, 0, 0.23)',
        padding: 2,
        borderRadius: 2,
        position: 'relative',
        maxWidth: maxWidthContainer,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <input
        accept="image/jpeg,image/png,image/jpg,image/gif,image/webp"
        style={{ display: 'none' }}
        id="upload-image-input"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="upload-image-input">
        <Button
          variant="contained"
          component="span"
          color="primary"
        >
          {imageSrc ? btnChangeText : btnUploadText}
        </Button>
      </label>

      {error && (
        <Alert severity="error" sx={{ marginTop: 2 }}>
          {error}
        </Alert>
      )}

      {imageSrc && (
        <Box
          sx={{
            marginTop: 2,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ position: 'relative', display: 'inline-block' }}>
            <img
              src={imageSrc}
              alt="Preview"
              style={{
                width: '100%',
                maxWidth: maxWidthImage,
                borderRadius: 8,
              }}
            />
            <IconButton
              onClick={handleRemoveImage}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                background: 'rgba(255, 255, 255, 0.8)',
                '&:hover': { background: 'rgba(255, 255, 255, 1)' },
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ImageUploader;
