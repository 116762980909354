import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import {
  Box,
  Container,
  Grid,
  Paper,
  FormControl,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import DatePickerComponent from "../../components/DatePicker";
import { useQuery } from "react-query";
import { getTherapistCalendarsDetail } from "../../api/therapist";

const pixelPer10Minutes = 15;
const pixel1Hour = pixelPer10Minutes * 6;

const TherapistShiftView = () => {
  const { id } = useParams();

  const [dateSelected, setDateSelected] = useState(dayjs(new Date()));
  const [calendarList, setCalendarList] = useState(
    Array.from({ length: 31 }, () => [])
  );

  const { data: dataDetail } = useQuery(
    ["calendar_detail", dateSelected],
    () => {
      const payload = {
        year_month: dateSelected.format("YYYY-MM"),
      };
      return getTherapistCalendarsDetail(id, payload);
    }
  );

  useEffect(() => {
    if (dataDetail) {
      setCalendarList(dataDetail.data);
    }
  }, [dataDetail]);

  const hours = useMemo(() => {
    const hoursArray = [];
    for (let i = 10; i < 29; i++) {
      const hour = dayjs()
        .hour(i % 24)
        .minute(0)
        .format("H:mm");
      hoursArray.push(hour);
    }
    return hoursArray;
  }, []);

  const daysInMonth = useMemo(() => {
    const currentMonth = dateSelected.startOf("month");
    const daysArray = [];
    for (let i = 0; i < currentMonth.daysInMonth(); i++) {
      const day = currentMonth.add(i, "day").format("MM月DD日");
      daysArray.push(day);
    }
    return daysArray;
  }, [dateSelected]);

  const renderDiv = (calendars) => {
    if (calendars.length === 0) return;
    const calendar = calendars[0];
    const start = dayjs(calendar.date_start, "YYYY-MM-DD HH:mm:ss");
    const end = dayjs(calendar.date_end, "YYYY-MM-DD HH:mm:ss");

    const startOfDay = dayjs(calendar.date_start, "YYYY-MM-DD HH:mm:ss")
      .startOf("day")
      .add(10, "hour");

    const startMinutes = start.diff(startOfDay, "minute");
    const endMinutes = end.diff(startOfDay, "minute");

    const minutesDifference = endMinutes - startMinutes;

    const totalWidth = (minutesDifference / 10) * pixelPer10Minutes;
    const diff = (startMinutes / 10) * pixelPer10Minutes;

    const count = Math.floor(minutesDifference / 10);

    return (
      <div
        style={{
          height: "100%",
          width: `${totalWidth}px`,
          marginLeft: `${diff}px`,
          background: "#fff",
          display: "flex",
          border: "2px solid #fff",
        }}
      >
        {Array.from({ length: count }, (_, index) => (
          <div
            key={index}
            style={{
              height: "100%",
              width: `${pixelPer10Minutes}px`,
              border: "1px dotted #000",
            }}
          ></div>
        ))}
        {count * pixelPer10Minutes < totalWidth && (
          <div
            style={{
              height: "100%",
              width: `${totalWidth - count * pixelPer10Minutes}px`,
              border: "1px dotted #000",
            }}
          ></div>
        )}
      </div>
    );
  };

  return (
    <>
      <HeaderReservationManagement />
      <Navbar />
      <Container
        maxWidth="lg"
        sx={{
          marginTop: "32px",
          maxWidth: "1500px !important",
          paddingBottom: "50px",
        }}
      >
        <Typography
          component="p"
          sx={{
            fontSize: {
              xs: 20,
              md: 28,
            },
            color: "#000",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "24px",
            letterSpacing: "3px",
            marginBottom: "16px",
            textDecoration: "none",
            width: "100%",
            paddingBottom: "15px",
            borderBottom: "2px solid black",
            textAlign: "left",
          }}
        >
          セラピストのシフト設定
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <Button
                variant="outlined"
                onClick={() =>
                  setDateSelected((prev) => prev.subtract(1, "month"))
                }
              >
                前週
              </Button>
            </Grid>
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <FormControl>
                <DatePickerComponent
                  currentDate={dateSelected}
                  onDateChange={setDateSelected}
                  views={["year", "month"]}
                  format="YYYY年MM"
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <Button
                variant="outlined"
                onClick={() => setDateSelected((prev) => prev.add(1, "month"))}
              >
                来週
              </Button>
            </Grid>
          </Grid>
        </Box>

        <TableContainer component={Paper}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ display: "flex" }}>
                  <TableCell sx={{ width: "100px" }}></TableCell>
                  <TableCell
                    sx={{
                      display: "block",
                      width: "100px",
                      border: "1px solid #e0e0e0",
                      textAlign: "center",
                      verticalAlign: "middle",
                      padding: "auto",
                    }}
                  >
                    店舗
                  </TableCell>
                  {hours.map((hour) => (
                    <TableCell
                      key={hour}
                      align="center"
                      sx={{
                        border: "2px solid #e0e0e0",
                        width: "90px",
                        padding: "auto",
                      }}
                    >
                      {hour}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {calendarList.map((calendars, index) => (
                  <TableRow
                    key={daysInMonth[index]}
                    sx={{ display: "flex", padding: 0 }}
                  >
                    <TableCell
                      align="center"
                      sx={{
                        width: "100px",
                        display: "block",
                        position: "sticky",
                        left: 0,
                        backgroundColor: "#fff",
                        border: "2px solid #e0e0e0",
                      }}
                    >
                      {daysInMonth[index]}
                    </TableCell>
                    <TableCell
                      sx={{ border: "2px solid #e0e0e0", width: "100px" }}
                    >
                      {calendars.length > 0 && calendars[0]?.store?.store_name}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: pixel1Hour * 19 + "px",
                        padding: 0,
                        backgroundColor: "#808080",
                      }}
                    >
                      {renderDiv(calendars)}
                    </TableCell>
                  </TableRow>
                ))}
                {calendarList.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={hours.length + 2} align="center">
                      <Typography>該当データがありません。</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TableContainer>
      </Container>
    </>
  );
};

export default TherapistShiftView;
