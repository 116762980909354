import { TablePagination } from "@mui/material";

function TablePaginationLayout({rowsPerPageOptions, rowsPerPage, total, page,onPageChange, handleChangeRowsPerPage}) {
  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={total}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}

export default TablePaginationLayout;
