import { sendDelete, sendGet, sendPost } from "./axios";

export const getListTherapist = (params) => sendGet("/therapists", params);
export const createTherapist = (params) => sendPost("/therapists", params);

export const updateTherapist = (id, params) =>
  sendPost(`/therapists/${id}`, params);

export const deleteTherapist = (id) => sendDelete(`/therapists/${id}`);

// Therapist Calendars
export const getListTherapistCalendars = (params) =>
  sendGet("/therapist-calendars", params);

export const saveTherapistCalendar = (id, params) =>
  sendPost(`/therapist-calendars/${id}`, params);

export const getTherapistCalendarsDetail = (id, params) =>
  sendGet(`/therapist-calendars/${id}`, params);

export const getCalendarByDate = (params) =>
  sendGet("/therapist-calendars/by-date", params);

export const deleteTherapistCalendar = (id) =>
  sendDelete(`/therapist-calendars/${id}`);

export const upOrderNumber = (id) =>
  sendPost(`/therapist-calendars/up-order-number/${id}`);

export const downOrderNumber = (id) =>
  sendPost(`/therapist-calendars/down-order-number/${id}`);

export const upTopOrderNumber = (id) =>
  sendPost(`/therapist-calendars/uptop-order-number/${id}`);

export const downBottomOrderNumber = (id) =>
  sendPost(`/therapist-calendars/downbot-order-number/${id}`);
