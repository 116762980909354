import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import {
  createIntroducer,
  deleteIntroducer,
  getListIntroducer,
  updateIntroducer,
} from "../../api/introducer";
import { isNumeric } from "../../utils";
const taskOptions = [
  { id: 1, value: "1件につき2000円" },
  { id: 2, value: "1件につき2500円" },
  { id: 3, value: "1件につき3000円" },
  { id: 4, value: "「セラピスト報酬」の15%＋出勤日数×1000円" },
  { id: 5, value: "「セラピスト報酬」の13%" },
  { id: 6, value: "「セラピスト報酬」の15%" },
  { id: 7, value: "「セラピスト報酬」の20%" },
];

const useIntroducerMaster = () => {
  const [arrIntroducers, setArrIntroducers] = useState([]);
  const [activeSortColumn, setActiveSortColumn] = useState(0);
  const [sortColumnDirection, setSortColumnDirection] = useState("desc");
  const [sortedData, setSortedData] = useState([]);
  const [newIntroducer, setNewIntroducer] = useState({
    name: "",
    task: "",
    remarks: "",
  });

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const [editIntroducer, setEditIntroducer] = useState({
    name: "",
    task: "",
    remarks: "",
  });
  const [isPopupOpenDelete, setIsPopupOpenDelete] = useState(false);
  const [isPopupOpenUpdate, setIsPopupOpenUpdate] = useState(false);
  const [introducerToDelete, setIntroducerToDelete] = useState(null);
  const [focusState, setFocusState] = useState({
    name: false,
    task: false,
  });

  const nameInputRef = useRef(null);

  const [errors, setErrors] = useState({
    name: "",
    task: "",
    remarks: "",
  });

  const [errorsUpdate, setErrorsUpdate] = useState({
    name: "",
    task: "",
    remarks: "",
  });

  const { data, refetch } = useQuery(
    ["introducers", page, rowsPerPage],
    () => {
      const params = {
        page: page + 1,
        per_page: rowsPerPage,
      };
      return getListIntroducer(params);
    },
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (data) {
      setArrIntroducers(transformIntroducerData(data.data.data));
      setTotal(data.data.total);
    }
  }, [data]);

  useEffect(() => {
    setSortedData(arrIntroducers.sort((a, b) => b.id - a.id));
  }, [arrIntroducers]);

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  const transformIntroducerData = (data) => {
    return data.map((introducer) => {
      const matchingOption = taskOptions.find(
        (option) => option.id === introducer.task
      );
      const taskName = matchingOption ? matchingOption.value.toString() : null;
      return {
        id: introducer.id.toString(),
        name: introducer.name,
        task: taskName,
        remarks: introducer.remarks || "",
      };
    });
  };

  const handleSort = (columnIndex) => {
    const isSorted = activeSortColumn === columnIndex;
    let direction = isSorted
      ? sortColumnDirection === "asc"
        ? "desc"
        : "asc"
      : "desc";
    sortedData.sort((a, b) => {
      let aValue = a[Object.keys(a)[columnIndex]];
      let bValue = b[Object.keys(b)[columnIndex]];

      // if aValue and bValue are numberic, sort parseInt
      if (isNumeric(aValue) && isNumeric(bValue)) {
        aValue = parseInt(aValue);
        bValue = parseInt(bValue);
      }

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setSortedData([...sortedData]);
    setActiveSortColumn(columnIndex);
    setSortColumnDirection(direction);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewIntroducer({ ...newIntroducer, [name]: value });
    setErrors({ name: "", task: "", remarks: "", });
  };

  const handleAddIntroducer = async () => {
    try {
      const newIntroducerData = {
        name: newIntroducer.name,
        task: newIntroducer.task,
        remarks: newIntroducer.remarks,
      };

      const response = await createIntroducer(newIntroducerData);

      if (response && response.data) {
        refetch();
        setNewIntroducer({ name: "", task: "", remarks: "" });
        setErrors({ name: "", task: "", remarks: "" });
      } else {
        alert("エラーが発生しました。");
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const fieldErrors = error.response.data.message;
        setErrors((prev) => ({
          ...prev,
          ...fieldErrors,
        }));
      }
      else {
        alert("エラーが発生しました。");
      }
    }
  };

  const handleUpdateIntroducer = async (updatedIntroducer) => {
    try {
      const { id, name, task, remarks } = updatedIntroducer;
      const updatedData = {
        name: name,
        task: task,
        remarks: remarks,
      };

      const response = await updateIntroducer(id, updatedData);
      if (response && response.data) {
        refetch();
        return true
      } else {
        alert("エラーが発生しました。");
        return false;
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const fieldErrors = error.response.data.message;
        setErrorsUpdate((prev) => ({
          ...prev,
          ...fieldErrors,
        }));
      }
      else {
        alert("エラーが発生しました。");
      }
      return false;
    }
  };

  const handleDeleteIntroducer = async (id) => {
    try {
      const response = await deleteIntroducer(id);

      if (response.success) {
        refetch();
      } else {
        alert("エラーが発生しました。");
      }
    } catch (error) {
      alert("エラーが発生しました。");
    }
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    const maxPage = Math.floor(total / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(Math.min(page, maxPage));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleFocus = (field) => {
    setFocusState((prevState) => ({
      ...prevState,
      [field]: true,
    }));
  };

  const handleBlur = (field) => {
    setFocusState((prevState) => ({
      ...prevState,
      [field]: false,
    }));
  };

  const handleClosePopup = () => {
    setIsPopupOpenUpdate(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditIntroducer((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrorsUpdate({ name: "", task: "" });
  };

  const handleEditClick = (introducer) => {
    const getFeeId = (taskValue) => {
      const option = taskOptions.find((o) => o.value === taskValue);
      return option ? option.id : "";
    };

    const taskId = getFeeId(introducer.task);
    setEditIntroducer({
      ...introducer,
      task: taskId,
    });
    setIsPopupOpenUpdate(true);
  };

  const handleDeleteClick = (introducer) => {
    setIntroducerToDelete(introducer);
    setIsPopupOpenDelete(true);
  };

  return {
    arrIntroducers,
    activeSortColumn,
    sortColumnDirection,
    sortedData,
    newIntroducer,
    rowsPerPage,
    page,
    total,
    editIntroducer,
    isPopupOpenDelete,
    isPopupOpenUpdate,
    introducerToDelete,
    focusState,
    nameInputRef,
    taskOptions,
    errors,
    errorsUpdate,
    handleRowsPerPageChange,
    handlePageChange,
    handleSort,
    handleInputChange,
    handleAddIntroducer,
    handleUpdateIntroducer,
    handleDeleteIntroducer,
    handleFocus,
    handleBlur,
    handleClosePopup,
    handleChange,
    handleEditClick,
    handleDeleteClick,
    setIsPopupOpenDelete,
  };
};

export default useIntroducerMaster;
