import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Button,
} from "@mui/material";
import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import { useCallback, useEffect, useState } from "react";
import TablePaginationLayout from "../../layouts/TablePagination";
import InfoIcon from '@mui/icons-material/Info';
import { isNumeric } from "../../utils";

const columnTitles = [
  "id",
  "操作内容",
  "変更箇所",
  "操作内容詳細",
  "日時",
  "時間",
];

const dataLogs = [
  { id: "1", operation: "delete", path: "/therapistmanagement", details: "mizuki → nothing", date: "2024/07/07", time: "09:00" },
  { id: "2", operation: "edit", path: "/therapistmanagement", details: "09:00 → 11:00", date: "2024/07/07", time: "09:00" },
  { id: "3", operation: "create", path: "/therapistmanagement", details: "nothing → yukiko", date: "2024/07/07", time: "09:00" },
  { id: "4", operation: "edit", path: "/therapistmanagement", details: "mizuki → nothing", date: "2024/07/07", time: "09:00" },
  { id: "5", operation: "delete", path: "/therapistmanagement", details: "mizuki → nothing", date: "2024/07/07", time: "09:00" },
  { id: "6", operation: "create", path: "/therapistmanagement", details: "nothing → 11:00", date: "2024/07/07", time: "09:00" },
  { id: "7", operation: "delete", path: "/therapistmanagement", details: "mizuki → nothing", date: "2024/07/07", time: "09:00" },
  { id: "8", operation: "edit", path: "/therapistmanagement", details: "mizuki → yukiko", date: "2024/07/07", time: "09:00" },
  { id: "9", operation: "create", path: "/therapistmanagement", details: "nothing → yukiko", date: "2024/07/07", time: "09:00" },
];

const getOperationDisplay = (operation) => {
  let color;
  switch (operation) {
    case "delete":
      color = 'red';
      break;
    case "edit":
      color = 'orange';
      break;
    case "create":
      color = 'green';
      break;
    default:
      color = 'black';
  }
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <InfoIcon sx={{ marginRight: '4px', color }} />
      {operation}
    </Box>
  );
};

const Log = () => {
  const [activeSortColumn, setActiveSortColumn] = useState(0);
  const [sortColumnDirection, setSortColumnDirection] = useState("desc");
  const [sortedData, setSortedData] = useState(dataLogs);

  const handleSort = useCallback((columnIndex) => {
    const isSorted = activeSortColumn === columnIndex;
    let direction = isSorted
      ? sortColumnDirection === "asc"
        ? "desc"
        : "asc"
      : "desc";
    sortedData.sort((a, b) => {
      let aValue = a[Object.keys(a)[columnIndex]];
      let bValue = b[Object.keys(b)[columnIndex]];

      // if aValue and bValue are numberic, sort parseInt
      if (isNumeric(aValue) && isNumeric(bValue)) {
        aValue = parseInt(aValue);
        bValue = parseInt(bValue);
      }

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setSortedData([...sortedData]);
    setActiveSortColumn(columnIndex);
    setSortColumnDirection(direction);
  }, [activeSortColumn, sortColumnDirection, sortedData]);

  useEffect(() => {
    handleSort(activeSortColumn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderReservationManagement />
      <Navbar />
      <Container maxWidth="lg" sx={{}}>
        <Typography
          component="p"
          sx={{
            fontSize: {
              xs: 20,
              md: 28,
            },
            color: "#fff",
            top: "104px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "24px",
            letterSpacing: "3px",
            margin: "80px 0px 16px",
            textDecoration: "none",
            width: "100%",
            paddingBottom: "15px",
            borderBottom: "2px solid white",
            textAlign: "left",
          }}
        >
          登録・変更内容のログ
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#E0E0E0',
              color: '#000',
              '&:hover': {
                backgroundColor: '#BDBDBD',
              },
              fontWeight: 'bold',
              padding: '8px 16px',
              borderRadius: '4px',
              boxShadow: 'none',
              border: '1px solid #BDBDBD',
            }}
          >
            登録・変更内容のログを保存
          </Button>
        </Box>
      </Container>
      <Container maxWidth="xl">
        <Box sx={{ marginTop: "40px" }}>
          <Paper sx={{ marginTop: "16px" }}>
            <TableContainer>
              <Table aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    {columnTitles.map((title, index) => {
                      const isSorted = activeSortColumn === index;
                      const sortDirection = isSorted ? sortColumnDirection : 'asc';

                      return (
                        <TableCell
                          key={index}
                          align="center"
                          sx={{ padding: "8px 16px" }}
                          sortDirection={isSorted ? sortDirection : false}
                        >
                          <TableSortLabel
                            active={isSorted}
                            direction={sortDirection}
                            onClick={() => handleSort(index)}
                          >
                            {title}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((row, rowIndex) => (
                    <TableRow key={rowIndex} className="MuiTableRow-hover">
                      {Object.entries(row).map(([key, value], cellIndex) => (
                        <TableCell key={cellIndex} align="center">
                          {key === "operation" ? getOperationDisplay(value) : value}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                  {sortedData.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={columnTitles.length} align="center">
                        <Typography>該当データがありません。</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationLayout
              rowsPerPage={50}
              rowsPerPageOptions={[50, 100, 125]}
            />
          </Paper>
        </Box>
      </Container>
    </>
  );
}

export default Log;
