import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import React from "react";
import RevenueArea from "../../features/dailyReport/RevenueArea";
import RevenueTherapist from "../../features/dailyReport/RevenueTherapist";
import RevenueIntroducer from "../../features/dailyReport/RevenueIntroducer";
import DailyReport from "../../features/dailyReport/DailyReport";
import { useQuery } from "react-query";
import { getListNomination } from "../../api/nomination";

function DailyReportPage() {
  const { data: dataNominatation } = useQuery(
    ["list_nomination"],
    () => {
      return getListNomination({ per_page: 300 });
    },
    {
      staleTime: Infinity,
    }
  );

  return (
    <>
      <HeaderReservationManagement />
      <Navbar />
      <RevenueArea />
      <RevenueTherapist dataNominatation={dataNominatation?.data?.data || []} />
      <RevenueIntroducer />
      <DailyReport dataNominatation={dataNominatation?.data?.data || []} />
    </>
  );
}

export default DailyReportPage;
