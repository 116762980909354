import {
  Container,
  Box,
  Typography,
  FormControl,
  Button,
  TextField,
  ButtonGroup,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import styleCustome from "./login.module.css";
import useLogin from "../../features/users/hooks/login";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { hasToken } from "../../api/axios";
function Login() {
  const navigate = useNavigate();
  const {
    ID,
    setID,
    password,
    setPassword,
    idError,
    passwordError,
    handleBlur,
    handleSubmitLogin,
  } = useLogin();

  useEffect(() => {
    if (hasToken()) {
      navigate("/staff_schedule");
    }
  }, [navigate]);

  return (
    <div >
      <Container maxWidth="sm">
        <Box
          className={styleCustome.customeBox}
          component="section"
          sx={{ p: 2, border: "1px none grey" }}
        >
          <Typography
            variant="h5"
            component="h1"
            className={`${styleCustome.title}`}
          >
            Lynx Group <br />
            一括管理システム
          </Typography>
          <Box component="form" width="100%">
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="ID"
                variant="outlined"
                margin="normal"
                value={ID}
                onChange={(e) => {
                  setID(e.target.value);
                }}
                onBlur={() => handleBlur("ID")}
                fullWidth
                required
                inputProps={{ maxLength: 128 }}
                error={Boolean(idError)}
                helperText={idError}
              />
              <TextField
                id="outlined-basic"
                label="Password"
                variant="outlined"
                margin="normal"
                value={password}
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                onBlur={() => handleBlur("password")}
                fullWidth
                required
                inputProps={{ minLength: 6, maxLength: 24 }}
                error={Boolean(passwordError)}
                helperText={passwordError}
              />
            </FormControl>
          </Box>
          <ButtonGroup
            variant="outlined"
            aria-label="Basic button group"
            fullWidth
            style={{ "marginTop": "50px" }}
          >
            <Button
              variant="outlined"
              type="submit"
              fullWidth
              onClick={handleSubmitLogin}
            >
              ログイン
            </Button>
            <Button variant="outlined" type="submit" fullWidth>
              <CalendarMonthIcon />
            </Button>
          </ButtonGroup>
        </Box>
      </Container>
    </div>
  );
}

export default Login;
