import { useEffect, useState } from "react";
import { getListRevenue } from "../../api/revenue";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import { formatCurrency, formatNumberWithUnit } from "../../utils";

const useRevenueByTherapist = () => {
  const [arrRevenues, setArrRevenues] = useState([]);
  const typeSearch = "by-therapist";
  const [startDate, setStartDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [total, setTotal] = useState(0);

  const fetchRevenueData = async () => {
    if (!startDate || !endDate) return [];
    const params = {
      start_date: startDate,
      end_date: endDate,
      page: page + 1,
      per_page: rowsPerPage,
    };
    const data = await getListRevenue(typeSearch, params);
    return data;
  };

  const { data } = useQuery(
    ["revenue", startDate, endDate, page, rowsPerPage],
    () => fetchRevenueData(),
    {
      keepPreviousData: true,
      enabled: Boolean(startDate && endDate),
    }
  );

  useEffect(() => {
    if (data) {
      setArrRevenues(transformRevenueData(data.data.data));
      setTotal(data.data.total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const transformRevenueData = (data) => {
    return data.map((revenue) => {
      const total_price = parseInt(revenue.total_price, 10);
      const total_customer = parseInt(revenue.total_customer, 10) || 1;
      const avg_price_per_customer = total_price / total_customer;

      return {
        therapist_name: revenue.therapist_name,
        total_price: formatCurrency(revenue.total_price),
        total_customer: formatNumberWithUnit(revenue.total_customer, "人"),
        total_nomination: formatNumberWithUnit(revenue.total_nomination, "件"),
        avg_price_per_customer: formatCurrency(avg_price_per_customer),
        nomination_fee: formatCurrency(revenue.nomination_fee),
      };
    });
  };

  const handleDateChange = (start, end) => {
    const formattedStartDate = start ? dayjs(start).format("YYYY-MM-DD") : "";
    const formattedEndDate = end ? dayjs(end).format("YYYY-MM-DD") : "";
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
    fetchRevenueData(formattedStartDate, formattedEndDate);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    const maxPage = Math.floor(total / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(Math.min(page, maxPage));
  };

  return {
    arrRevenues,
    startDate,
    endDate,
    rowsPerPage,
    page,
    total,
    handleDateChange,
    handlePageChange,
    handleRowsPerPageChange,
  };
};

export default useRevenueByTherapist;
