import { useEffect, useState } from "react";
import {
  getListCustomer,
} from "../../api/orders";
import { useQuery } from "react-query";
import { isNumeric } from "../../utils";

const useCustomerMGR = () => {
  const [arrCustomers, setArrCustomers] = useState([]);
  const [activeSortColumn, setActiveSortColumn] = useState(0);
  const [sortColumnDirection, setSortColumnDirection] = useState("desc");
  const [sortedData, setSortedData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [debouncedKeyword, setDebouncedKeyword] = useState("");

  const { data } = useQuery(
    ["orders", page, rowsPerPage, debouncedKeyword],
    () => {
      const params = {
        page: page + 1,
        per_page: rowsPerPage,
        key_word: debouncedKeyword,
      };
      return getListCustomer(params);
    },
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (data) {
      setArrCustomers(transformCustomerData(data.data.data));
      setTotal(data.data.total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setSortedData(arrCustomers.sort((a, b) => a.order_date < b.order_date ? 1 : -1));
  }, [arrCustomers]);

  const transformCustomerData = (data) => {
    return data.map((customer) => ({
      order_date: formatOrderDate(customer.order_date),
      customer_name: customer.customer_name,
      therapist_name: customer.therapist_name,
      customer_type: customer.customer_type,
      customer_tel: customer.customer_tel,
    }));
  };

  const formatOrderDate = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}年${month}月${day}日 ${hours}:${minutes}`;
  };

  const handleSort = (columnIndex) => {
    const isSorted = activeSortColumn === columnIndex;
    const direction = isSorted
      ? sortColumnDirection === "asc"
        ? "desc"
        : "asc"
      : "desc";
    const sorted = [...sortedData].sort((a, b) => {
      let aValue = a[Object.keys(a)[columnIndex]];
      let bValue = b[Object.keys(b)[columnIndex]];

      // if aValue and bValue are numberic, sort parseInt
      if (isNumeric(aValue) && isNumeric(bValue)) {
        aValue = parseInt(aValue);
        bValue = parseInt(bValue);
      }

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setSortedData(sorted);
    setActiveSortColumn(columnIndex);
    setSortColumnDirection(direction);
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    const maxPage = Math.floor(total / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(Math.min(page, maxPage));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleTypeChange = (event, rowIndex) => {
    const newDataCustome = [...arrCustomers];
    newDataCustome[rowIndex].customer_type = event.target.value;
    setArrCustomers(newDataCustome);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleInputchange = (event) => {
    setSearchKeyword(event.target.value);
  }

  const handleSearch = () => {
    setDebouncedKeyword(searchKeyword);
  };

  return {
    arrCustomers,
    activeSortColumn,
    sortColumnDirection,
    sortedData,
    rowsPerPage,
    page,
    total,
    isFocused,
    handleRowsPerPageChange,
    handlePageChange,
    handleSort,
    handleTypeChange,
    handleFocus,
    handleBlur,
    handleSearch,
    handleInputchange
  };
};

export default useCustomerMGR;
