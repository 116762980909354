import Axios from 'axios';
import configs from '../config';

export const setToken = (token) => {
  localStorage.setItem('token', token);
}

const getToken = () => localStorage.getItem('token');

const removeToken = () => localStorage.removeItem('token');

export const hasToken = () => !!localStorage.getItem('token');

export const setUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
}

export const getUser = () => JSON.parse(localStorage.getItem('user'));

const removeUser = () => localStorage.removeItem('user');

const axiosInstance = Axios.create({
  timeout: 3 * 60 * 1000,
  baseURL: configs.API_DOMAIN,
});

axiosInstance.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${getToken()}`;
    return config;
  },
  (error) => Promise.reject(error)
);

export const logout = () => {
  removeToken();
  removeUser();
  if (window.location.pathname !== '/login') {
    window.location.href = '/login';
  }
};

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      if (hasToken()) {
        logout();
      }
    }
    return Promise.reject(error);
  }
);

export const sendGet = (url, params) => axiosInstance.get(url, { params }).then((res) => res.data);
export const sendPost = (url, params, queryParams) =>
  axiosInstance.post(url, params, { params: queryParams }).then((res) => res.data);
export const sendPut = (url, params) => axiosInstance.put(url, params).then((res) => res.data);
export const sendPatch = (url, params) => axiosInstance.patch(url, params).then((res) => res.data);
export const sendDelete = (url, params) => axiosInstance.delete(url, { params }).then((res) => res.data);
