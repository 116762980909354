import {
  AppBar,
  Button,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import { logout } from "../../api/axios";

function HeaderReservationManagement() {
  return (
    <AppBar
      position="static"
      color="primary"
      className="header-style"
      component="header"
    >
      <Toolbar>
        <Grid container direction="row" justifyContent="space-between">
          <Grid>
            <Typography variant="h6" component="div" className="header-title">
              LynxGroup
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="h5" component="div" className="login-username">
              ログイン者 :<span>ゲスト</span>
            </Typography>
          </Grid>
          <Grid>
            <Button
              variant="outlined"
              type="submit"
              className="btn-logout"
              onClick={logout}
            >
              ログアウト
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default HeaderReservationManagement;
