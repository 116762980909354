import { useState } from "react";

const usePaginate = (defaultPage = 0, defaultPerPage = 50) => {
  const [page, setPage] = useState(defaultPage);
  const [perPage, setPerPage] = useState(defaultPerPage);
  const [total, setTotal] = useState(0);

  const changePage = (_, newPage) => {
    setPage(newPage);
  };

  const changePerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    const maxPage = Math.floor(total / newRowsPerPage);
    setPerPage(newRowsPerPage);
    setPage(Math.min(page, maxPage));
  };
  return {
    page,
    perPage,
    total,
    changePage,
    setPage,
    setPerPage,
    setTotal,
    changePerPage,
  };
};

export default usePaginate;
